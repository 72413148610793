import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Link } from 'gatsby'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import Layout from 'src/layouts/BaseLayout'
import Icon from 'src/components/UI/MarkdownIcon'
import { FixedIncome } from 'src/components/InvestmentSimulators'
import InvestmentsTab from 'src/components/InvestmentsTab'
import FAQ from 'src/components/Faq'
import BlogArticles from 'src/components/BlogArticles'
import ScrollTo from 'src/components/ScrollTo'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import cdbJSON from './cdb.json'

import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { Section, FAQSection, Button } from './style'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'
const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaCdb } = qrcodeInvestimentsData

function RendaFixaCDB () {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isModalOpen, setIsModalOpen ] = React.useState(false)
  const domReady = useDomReady()

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={qrCodeAberturaDeContaCdb.qrcode}
        asFilledLink={qrCodeAberturaDeContaCdb.deeplink}
      />
    </Modal>
  )

  const dataLayer = {
    section: 'dobra_05',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const handleDatalayerEvent = () => {
    sendDatalayerEvent({
      section: 'dobra_2',
      element_action: 'click button',
      element_name: 'Comece a investir agora',
      section_name: 'Segurança e rentabilidade acima da poupança',
    })
  }
  return (
    <Layout pageContext={pageContext}>
      {cardsModal}
      <Section>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 pt-3 justify-content-center align-items-end space-letf order-md-last'>
              <Img fluid={data.bannerHero.fluid} alt='Tela do Super App da Inter Invest mostrando como investir em CDB.' />
            </div>
            <div className='d-flex col-12 col-md-6 align-items-center'>
              <div className='row pl-md-3'>
                <div className='col-12 mb-3 pl-1'>
                  <div className='bread'>
                    <Link
                      to='/'
                      className='d-md-inline'
                    ><Home height={24} width={24} color='#6A6E81' />
                    </Link>
                    <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                    <Link
                      to='/pra-voce/investimentos/'
                      className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                    >
                      {cdbJSON.breadcrumb[0]}
                    </Link>
                    <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                    <Link
                      to='/pra-voce/investimentos/renda-fixa/'
                      className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mr-2'
                    >
                      {cdbJSON.breadcrumb[1]}
                    </Link>
                    <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                    <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{cdbJSON.breadcrumb[2]}</p>
                  </div>
                </div>
                <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-4 py-lg-0 text-grayscale--500 fw-600'>
                  CDB: invista a partir
                  <span className='d-xl-block'> de R$ 1,00 com </span>
                  rentabilidade e segurança
                </h1>
                <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--400 mb-4'>
                  Investir em CDB (Certificado de Depósito Bancário)
                  <span className='d-xl-block'> é tão seguro quanto a poupança e você pode </span>
                  resgatar o dinheiro quando quiser.
                </p>
                <div className='col-md-12 my-md-0 px-0'>
                  <ScrollTo
                    to='#outros-investimentos'
                    section='dobra_01'
                    sectionName='CDB: invista a partir de R$ 1,00 com rentabilidade e segurança'
                    elementName='Simule agora!'
                  >
                    <Button>
                      Simule Agora!
                    </Button>
                  </ScrollTo>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section id='outros-investimentos' className='pt-5 pb-md-5'>
        <div className='container pb-lg-3'>
          <h2 className='fs-28 fs-md-42 text-md-center mt-2 mb-4 text-grayscale--500 font-sora'>Segurança e rentabilidade acima da poupança</h2>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 px-0'>
              <p className='fs-16 text-md-center text-grayscale--400'>Veja as caracteristicas do CDB, faça a simulação e veja seu dinheiro render.</p>
            </div>
          </div>
          <div className='row box-features'>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='pagamentos/ir' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Imposto de Renda<br />
                    <span className='fs-17 fw-400'>Regressivo</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='products/investimentos/fundos-2' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Investimento inicial de<br />
                    <span className='fs-17 fw-400'>R$ 1,00</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='pagamentos/fast-money' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Resgate<br />
                    <span className='fs-17 fw-400'>Diário / No vencimento</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md col-lg mt-2'>
              <div className='col-12 border rounded-3 d-flex d-md-block'>
                <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                  <Icon width='40' height='40' icon='action/calendar' directory='v2' />
                </div>
                <div className='col-10 col-md-12'>
                  <p className='fs-14 lh-20 mt-3 text-md-center'>Prazo mínimo<br />
                    <span className='fs-17 fw-400'>Variado</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='pt-5 d-md-none d-lg-none'>
            <h2 className='fs-17 text-grayscale--500 font-sora'>Veja um exemplo de como o seu dinheiro pode render.</h2>
          </div>
          <div className='shadow mt-4 mb-3 rounded-4 p-1 p-md-3'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-4'>
                <div className='box-content mt-0 mt-lg-5 mb-4'>
                  <p className='fw-700 mb-1 text-grayscale--500'>Considerações utilizadas nos exemplos:</p>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>Títulos Isentos de IR (LCI DI e Poupança) estão com taxas Gross-Up (consideramos a taxa como se o investimento fosse sujeito a IR, como um CDB). Assim, todos os valores demonstrados são brutos.</li>
                    <li>A expectativa é de que a <a href='https://blog.inter.co/taxa-selic-hoje/'>taxa Selic</a> oscile durante o período, do contrário, a rentabilidade poderá ser afetada.</li>
                    <li>Considera-se que o título do Tesouro Direto é a LFT (Tesouro Selic) e que sua rentabilidade é de 100% do CDI.</li>
                  </ul>
                </div>
                <div className='d-block d-lg-none'>
                  <a
                    onClick={() => {
                      handleDatalayerEvent()
                    }}
                    href={qrCodeAberturaDeContaCdb.deeplink}
                    className='btn btn--orange btn--lg rounded-3 mw-100'
                  >
                    Comece a investir agora
                  </a>
                </div>
                <div className='d-none d-lg-block'>
                  <div
                    onClick={() => {
                      setIsModalOpen(true)
                      handleDatalayerEvent()
                    }}
                    className='btn btn--orange btn--lg rounded-3 mw-100'
                  >
                    Comece a investir agora
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-8 px-0'>
                <FixedIncome products='cdb,poupanca' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='outros-investimentos'>
        <div className='container pt-5 py-md-5'>
          <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 font-sora mt-2'>Veja outros tipos de investimentos</h2>
          <InvestmentsTab
            section='dobra_3'
            elementAction='click button'
            section_name='Veja outros tipos de investimentos'
          />
        </div>
      </section>

      <section id='novidades-investimentos' className='py-md-4'>
        <div className='container'>
          <div className='col-12 mb-4'>
            <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center text-grayscale--500 font-sora'>Os melhores conteúdos sobre investimentos</h2>
            <p className='text-left mt-1 text-lg-center mb-0 text-grayscale--400'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <BlogArticles
            type='investments'
            section='dobra_4'
            elementAction='click button'
            elementName='Saiba mais'
            sectionName='Confira algumas dicas para investir melhor'
          />
        </div>
      </section>

      <FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-4 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 font-sora'>Perguntas frequentes</h2>
              <p className='fs-16 text-md-center text-grayscale--400'>
                <span className='d-lg-block'>Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas</span> perguntas ou entre em contato com a gente.
              </p>
            </div>
            <FAQ
              id='investimentos-cdb'
              className='summary-content px-0'
              answerData={pageContext.structuredData.faq}
              searchBg='#fff'
            />
          </div>
        </div>
      </FAQSection>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_05'
      />
    </Layout>
  )
}

export default RendaFixaCDB
